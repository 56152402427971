import { createWebHistory, createRouter } from "vue-router";
import Login from "./components/Login.vue";
import About from "./components/About.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardWork = () => import("./components/BoardWork.vue")
const Employye = () => import("./components/Employee.vue")
const Compa = () => import("./components/Compa.vue")
const TodoItems = () => import("./components/TodoItems.vue")
const TodoItem = () => import("./components/TodoItem.vue")
const Media = () => import("./components/Media.vue")
const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/work",
    name: "work",
    // lazy-loaded
    component: BoardWork,
  },
  {
    path: "/employee",
    name: "employee",
    // lazy-loaded
    component: Employye,
  },
  {
    path: "/compa",
    name: "compa",
    // lazy-loaded
    component: Compa,
  },
  {
    path: "/todoItems",
    name: "todoItems",
    component: TodoItems
  },
  {
    path: "/todoItem/:id",
    name: "todoItem",
    component: TodoItem,
    props: route => ({ id: Number(route.params.id) })
  },
  {
    path: "/media/",
    name: "Media",
    component: Media,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;