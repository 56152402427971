<template>
  <Form @submit="handleLogin" :validation-schema="schema">
    <div class="loginform">
      <div class="subtitle">Zaloguj się</div>
      <div class="input-container ic2">
        <!-- <input id="email" class="input" type="text" placeholder=" "/> -->
        <Field id="email" name="username" type="text" class="input" placeholder="" />
        <div class="cut"></div>
        <label for="email" class="placeholder">Wpisz swój adres e-mail:</label>
        <ErrorMessage name="username" class="error-feedback" />
      </div>
      <div class="input-container ic2">
        <!-- <input id="password" class="input" type="password" placeholder=" " /> -->
        <Field name="password" type="password" class="input" placeholder="" />
        <div class="cut cut-short"></div>
        <label for="password" class="placeholder">Hasło:</label>
        <ErrorMessage name="password" class="error-feedback" />
      </div>
      <button type="submit" class="submit">Zaloguj się</button>
      <h5><br></h5>
      <div class="form-group">
        <div v-if="message">
          {{ message }}
        </div>
      </div>
      <h5><br></h5>
      <div class="l11">lub</div>
      <router-link to="/loginbyemail" class="btn">Zaloguj się przez e-mail</router-link>
      <h5><br></h5>
      <h5><br></h5>
      <div class="l11">lub</div>
      <router-link to="/register" class="btn">Zarejestruj się</router-link>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Wprowadź e-mail !"),
      password: yup.string().required("Wprowadź hasło !"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/profile");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
.error-feedback {
  color: red;
  margin-left: 100px;
}
h1, h2, h3, h4, h5, h6, a {
  margin:0; padding:0;
}
h6
{
  color: #B8B8B8;
}
h7
.a {
  color: #ffffff;
}
.l11 {
  font-family: Inter, sans-serif;
  color: #B8B8B8;
  display: flex;
  flex-direction: row;
}
.l11:before, .l11:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
  background: #B8B8B8;
}
.l11:before {
  margin-right: 10px
}
.l11:after {
  margin-left: 10px
}
.loginform {
  border:0px solid #1124FF;
  border-radius: 20px;
  box-sizing: border-box;
  height: 500px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  z-index: -1;
}

.title {
  color: #0000;
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  /* color: #0000; */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.input-container {
  height: 50px;
  position: relative;/*   */
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.ic3 {
  margin-top: 20px;
}

.input {
  /*background-color: #303245;*/
  border-radius:23px;
  border:1px solid #B8B8B8;
  box-sizing: border-box;
  color: #B8B8B8;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: #ffffff;
  color: #B8B8B8;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute; /* */
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 155px;
}

.cut-short {
  width: 50px;
}

.cut-mid {
  width: 70px;
}
.cut-mid2 {
  width: 90px;
}
.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #B8B8B8;
  font-family:Inter, sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}
/* .label {
  font-family:Inter, sans-serif;
  top: 0px;
} */
.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #B8B8B8;
}

.input:focus ~ .placeholder {
  color: #1124FF;
}

.submit, .btn {
  appearance: button;
  color: #1124FF;
  background:#fff;
  border:1px solid #1124FF;
  border-radius:23px;
  /* color: #fff; */
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  font-family:Inter, sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  width: 100%;
  text-decoration: none;
}
.btn {
display: flex;
flex-direction: column;
justify-content: space-around;
}
.submit:active {
  background-color: #B8B8B8;
}
</style>
